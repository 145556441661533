/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { gql } from '@apollo/client'
import { CtaBannerFragment } from '../__generated__/bannerCta'
import { SimpleCta } from '../../../../components/simplecta/SimpleCta'
import { useMediaQuery } from '@material-ui/core'
import { theme } from '../../../../theme'
import {
  CTASubTitle,
  CTASubTitleBold,
  CTATitle,
  InternalCTAWrapper,
  MobileWrapper,
  StyledCTAWrapper,
  TitleWrapper,
} from './styledBannerCta'
import { useCTALinksContext, MobileLinks, DesktopLinks } from './ctaLinks'



export type BannerCTAProps = {
  title: string
  data: CtaBannerFragment[]
}



export const BannerCTA: React.VFC<BannerCTAProps> = ({ title, data }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { selectedCTAFilter } = useCTALinksContext()

  return (
    <StyledCTAWrapper>
      <SimpleCta>
        <InternalCTAWrapper>
          {isMobile ? (
            <MobileWrapper>
              <CTATitle>{title}</CTATitle>
              {typeof selectedCTAFilter !== 'number'  ? (
                <CTASubTitle><CTASubTitleBold>I am...</CTASubTitleBold></CTASubTitle>
              ) : (
                <CTASubTitle>
                  {/* @ts-ignore */}
                  I am <CTASubTitleBold orange>{data?.[selectedCTAFilter]?.ctaLinkTitle}</CTASubTitleBold> {!data?.[selectedCTAFilter]?.prompt || data?.[selectedCTAFilter]?.prompt === 'lookingFor' ? 'looking for...' : (data?.[selectedCTAFilter]?.prompt === 'about' ? 'about...' : '...')}
                </CTASubTitle>
              )}
              <MobileLinks data={data} />
            </MobileWrapper>
          ) : (
            <>
              <TitleWrapper>
                <CTATitle>{title}</CTATitle>
                {typeof selectedCTAFilter !== 'number'  ? (
                  <CTASubTitle><CTASubTitleBold>I am...</CTASubTitleBold></CTASubTitle>
                ) : (
                  <CTASubTitle>
                    {/* @ts-ignore */}
                    I am <CTASubTitleBold orange>{data?.[selectedCTAFilter]?.ctaLinkTitle}</CTASubTitleBold> {!data?.[selectedCTAFilter]?.prompt || data?.[selectedCTAFilter]?.prompt === 'lookingFor' ? 'looking for...' : (data?.[selectedCTAFilter]?.prompt === 'about' ? 'about...' : '...')}
                  </CTASubTitle>
                )}
              </TitleWrapper>
              <DesktopLinks data={data} />
            </>
          )}
        </InternalCTAWrapper>
      </SimpleCta>
    </StyledCTAWrapper>
  )
}

export const BANNER_CTA_FRAGMENT = gql`
  fragment CTABannerFragment on bannerCta_BlockType {
    ctaLinkTitle
    prompt
    ctaLinks {
      ... on ctaLinks_ctaLinks_BlockType {
        id
        ctaLink {
          customText
          url
        }
      }
    }
  }
`
