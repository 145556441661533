import styled from 'styled-components'
import { StyledTrapezoid, StyledDoubleTriangle } from '../../../../components/simplecta/StyledSimpleCta'
import { Typography } from '@material-ui/core'
import { LinkGrid } from '../../../../components/linkgrid/LinkGrid'
import { Select } from '../../../../components/form/Select'
import { Button } from '../../../../components/button/button'
import { StyledWrapper } from '../../../../components/simplecta/StyledSimpleCta'
import { StyledGridLinkIcon } from '../../../../components/linkgrid/StyledLinkGrid'

export const StyledCTAWrapper = styled.div`
  background: #f8f8f8;

  & ${StyledWrapper} {
    max-width: ${props => props.theme.typography.pxToRem(1300)};

    ${props => props.theme.breakpoints.up('lg')} {
      max-width: ${props => props.theme.typography.pxToRem(1368)};
      padding-right: ${props => props.theme.typography.pxToRem(45)};
    }
  }

  & ${StyledTrapezoid} {
    padding: ${props => props.theme.typography.pxToRem(34)};
    margin-left: 0;
    margin-right: 0;
    width: unset;
    margin-top: ${props => props.theme.typography.pxToRem(-50)};
    padding: ${props => props.theme.typography.pxToRem(60)} ${props => props.theme.typography.pxToRem(54)} !important;

    ${props => props.theme.breakpoints.down('md')} {
      margin-right: ${props => props.theme.typography.pxToRem(27)};
      margin-left: ${props => props.theme.typography.pxToRem(27)};
      clip-path: polygon(100% 45%, 100% 100%, 0 100%, 0 0, 86% 0);

    }
  }

  & ${StyledDoubleTriangle} {

    right: ${props => props.theme.typography.pxToRem(20)};
    bottom: ${props => props.theme.typography.pxToRem(-13)};

    ${props => props.theme.breakpoints.down('md')} {
      top: ${props => props.theme.typography.pxToRem(30)};
      right: ${props => props.theme.typography.pxToRem(16)};
    }

    ${props => props.theme.breakpoints.down('xs')} {
      top: 35px;
      right: 10px;
    }
  }

  ${props => props.theme.breakpoints.up('lg')} {
    margin-top: ${props => props.theme.typography.pxToRem(-155)};

    & ${StyledTrapezoid} {
      padding: ${props => props.theme.typography.pxToRem(63)} ${props => props.theme.typography.pxToRem(66)};
    }
  }
`

export const InternalCTAWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-width: ${props => props.theme.typography.pxToRem(276)};
  border-right: solid 1px rgba(112, 112, 112, 0.2);

  ${props => props.theme.breakpoints.up('lg')} {
    min-width: unset;
    width: ${props => props.theme.typography.pxToRem(304)};
    padding-right: ${props => props.theme.typography.pxToRem(22)};
  }
`

export const CTATitle = styled(Typography)`
  text-transform: uppercase;
  color: #1C1F2A;
  font-size: ${props => props.theme.typography.pxToRem(13)};
  font-weight: 500;
  letter-spacing: ${props => props.theme.typography.pxToRem(0.65)};


  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(15)};\
    margin-bottom: ${props => props.theme.typography.pxToRem(21)};
    letter-spacing: ${props => props.theme.typography.pxToRem(1.5)};
  }
`

export const CTASubTitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(21)};
  padding-bottom: ${props => props.theme.typography.pxToRem(15)};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(28)};
    max-width: ${props => props.theme.typography.pxToRem(327)};
  }
`

export const CTASubTitleBold = styled.span<{ orange?: boolean }>`
  font-size: ${props => props.theme.typography.pxToRem(26)};
  font-weight: 500;
  color: ${props => props.orange ? '#D83B00' : '#1C1F2A'};


  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(30)};
  }
`

export const StyledLink = styled(LinkGrid)`
  width: unset;


  & > a {
    font-size: ${props => props.theme.typography.pxToRem(17)} !important;
    height: ${props => props.theme.typography.pxToRem(52)} !important;
    white-space: nowrap;
    padding: ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.typography.pxToRem(18)} !important;

    & > ${StyledGridLinkIcon} {
      font-size: ${props => props.theme.typography.pxToRem(18)} !important;
      font-weight: 500;
      padding-left: ${props => props.theme.typography.pxToRem(5)} !important;
    }
  }
`

export const LinkWrapper = styled(LinkGrid)`
  display: flex;
  flex-direction: row;
  padding-left: ${props => props.theme.typography.pxToRem(45)};
  justify-content: flex-start;
  flex-wrap: wrap;
  width: ${props => props.theme.typography.pxToRem(600)};
  row-gap: ${props => props.theme.typography.pxToRem(15)};
  column-gap: ${props => props.theme.typography.pxToRem(11)};

  ${props => props.theme.breakpoints.up('lg')} {
    width: ${props => props.theme.typography.pxToRem(840)};
  }
`

export const SecondaryLinksWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items; flex-end;
  justify-content: space-between;
  position: relative;
  width: 100%;
  
  & > a {
    align-self: end;
    color: black;
    margin-top: ${props => props.theme.typography.pxToRem(20)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    width: ${props => props.theme.typography.pxToRem(840)};
  }
`

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`

export const StyledSelect = styled(Select)`
  background: #F2F2F2;
  padding-buttom: ${props => props.theme.typography.pxToRem(20)};
  border: none;

  &:focus-within {
    border: ${props => props.theme.typography.pxToRem(0.941)} rgba(77,77,77,0.19) solid;
    box-shadow: none;
  }

  > .MuiSvgIcon-root {
    color: #F35A21;
  }

  > .MuiInputBase-input {
    font-weight: 500;
    font-size: ${props => props.theme.typography.pxToRem(17)};
  }
`

export const DropdownWrapper = styled.div`
  width: 100%;
`

export const NextButton = styled(Button)`
  margin-top: ${props => props.theme.typography.pxToRem(20)};
  padding: ${props => props.theme.typography.pxToRem(12)} ${props => props.theme.typography.pxToRem(30)};
  font-size: ${props => props.theme.typography.pxToRem(16)};
  line-height: ${props => props.theme.typography.pxToRem(16)};
  cursor: pointer;

  &::before, &::after {
    display: none;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  & > a {
    align-self: end;
    color: black;
  }
`